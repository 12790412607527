import React, { useState } from 'react'
// IMPORTACIONES PARA TABLE MUI
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import TablePagination from '@mui/material/TablePagination'
// IMPORTACIONES PARA EL FEEDBACK
import Snackbar from '@mui/material/Snackbar'
import MuiAlert from '@mui/material/Alert'
import { useNavigate } from 'react-router-dom'
import { Typography } from '@mui/material'

// CONFIGURACION DE FEEDBACK
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

export const CreateOperacionDevolucion = () => {
  const [files, setFiles] = useState([])

  // Manejar el cambio de archivos
  const handleFileChange = (event) => {
    // Obtener los archivos seleccionados
    const newFiles = Array.from(event.target.files)
    // Combinar los archivos anteriores con los nuevos
    setFiles((prevFiles) => [...prevFiles, ...newFiles])
  }


  return (
    <>
      <div className="container mx-auto mt-5" style={{ maxWidth: '600px' }}>
        <h1 className="text-center">Formulario de devolución de productos</h1>

        {/* DATOS DE LA REQUISICION */}
        <div className="row mt-4">
          <div className="card">
            <h6 className="card-header">Datos Generales</h6>
            <div className="card-body">
              <div className="mb-3 row">
                <div className="col-md-12">
                  <div className="mb-3 row">
                    <label className="col-md-6 col-form-label"><b>Fecha del reclamo u observación:</b></label>
                    <div className="col-md-6">
                      <input
                        type="datetime-local"
                        className="form-control"
                      />
                    </div>
                    {/* SEARCH NAME PRODUCTO */}
                    <div className="col-md-8">
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="mb-3 row">
                    <label className="col-md-6 col-form-label"><b>Cliente:</b></label>
                    <div className="col-md-6">
                      <select
                        type="select"
                        className="form-control"
                      />
                    </div>
                    {/* SEARCH NAME PRODUCTO */}
                    <div className="col-md-8">
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="mb-3 row">
                    <label className="col-md-6 col-form-label"><b>Telefono:</b></label>
                    <div className="col-md-6">
                      <input
                        type="select"
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <label htmlFor="nombre" className="form-label">
                    <b>Producto:</b>
                  </label>
                  {/* <input type="text" name="codLotProd" className="form-control" /> */}
                  <div className="col-md-12">
                    <input
                      type="text"
                      name="codLotProd"
                      value="Materiales de producción"
                      className="form-control"
                      readOnly
                      style={{ marginBottom: '20px' }}
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="mb-3 row">
                    <label className="col-md-6 col-form-label"><b>Codigo identificación o lote del producto:</b></label>
                    <div className="col-md-6">
                      <input
                        type="select"
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="mb-3 row">
                    <label className="col-md-6 col-form-label"><b>Cantidad de producto observado:</b></label>
                    <div className="col-md-6">
                      <input
                        type="number"
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                {/* <div className="col-md-12 mt-3">
                  <label htmlFor="nombre" className="form-label">
                    <b>Tipo de reclamo</b>
                  </label>
                  <input
                    type="text"
                    name="codLotProd"
                    value="Nombre del producto"
                    className="form-control"
                    readOnly
                  />
                </div> */}
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-4">
          <div className="card">
            <h6 className="card-header">
              Detalle del reclamo
            </h6>
            <div className="card-body">
              <form className="row mb-4 mt-4">
                {/* AGREGAR MATERIA PRIMA */}
                <div className="col-md-12">
                  <div className="mb-3 row">
                    <label className="col-md-12 col-form-label"><b>Ingrese el detalle u observación:</b></label>
                    <div className="col-md-12">
                      <textarea
                        type="select"
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>

                {/* AGREGAR CANTIDAD */}
                <div className="col-md-12">
                  <div className="mb-3 row">
                    <label className="col-md-12 col-form-label"><b>Evidencias del reclamo:</b></label>
                    <div className="col-md-12">
                      <input
                        type="file"
                        className="form-control"
                        multiple // Permite la selección de varios archivos
                        onChange={handleFileChange} // Maneja el cambio de archivos
                      />
                    </div>
                  </div>
                  {/* Mostrar los nombres de los archivos seleccionados */}
                  {files.length > 0 && (
                    <div className="mt-3">
                      <h5>Archivos seleccionados:</h5>
                      <ul>
                        {files.map((file, index) => (
                          <li key={index}>{file.name}</li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>

                {/* BOTON AGREGAR MATERIA PRIMA */}
                {/* <div className="col-md-12 d-flex justify-content-end">
                  <button className="btn btn-primary">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-plus-circle-fill me-2"
                      viewBox="0 0 16 16"
                    >
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
                    </svg>
                    Agregar
                  </button>
                </div> */}
              </form>
              <div className="btn-toolbar mt-4 d-flex justify-content-between">
                <button
                  type="button"
                  className="btn btn-secondary me-2"
                  onClick={() => window.close()}
                  style={{ width: '100px' }}
                >
                  Volver
                </button>

                <button type="submit" className="btn btn-primary" style={{ width: '100px' }}>
                  Guardar
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* BOTONES DE CANCELAR Y GUARDAR */}

      </div>



    </>
  )
}
