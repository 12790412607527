import React from 'react'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material'
import { CustomDialogUpdateOperation } from '../../../components/CustomComponents/CustomDialogUpdateOperation'
import { CustomDialogDeleteOperation } from '../../../components/CustomComponents/CustomDialogDeleteOperation'
import { CustomDialogConfirmOperation } from '../../../components/CustomComponents/CustomDialogConfirmOperation'

import iconStockAlmacenes from '../../../assets/icons/stock-almacenes.png'
import { getStockAlmacenes } from '../../../almacen/helpers/consult-stock/getStockAlmacenes'
import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}))
export const RowDetalleRequisicionAgregacion = ({
  detalles,
  onUpdateDetalle,
  onDeleteDetalle,
  onCheckDetalle
}) => {
  return (
    <div className="mt-2">
      <p>
        <b>Detalle</b>
      </p>
      <TableContainer key={detalles.id} component={Paper}>
        <Table>
          <TableHead style={{ backgroundColor: '#FEE7BC' }}>
            <TableRow>
              <TableCell>
                <b>#</b>
              </TableCell>
              <TableCell>
                <b>Producto</b>
              </TableCell>
              <TableCell>
                <b>Medida</b>
              </TableCell>
              <TableCell>
                <b>Cantidad</b>
              </TableCell>
              <TableCell>
                <b>Estado</b>
              </TableCell>
              <TableCell>
                <b>Acciones</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {detalles.map((detalle, index) => (
              <TableRow key={`${detalle.id} - ${index}`}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{detalle.nomProd}</TableCell>
                <TableCell>{detalle.simMed}</TableCell>
                <TableCell>{detalle.canReqAgrDet}</TableCell>
                <TableCell>
                  <span
                    className={
                      detalle.esComReqAgrDet === 0
                        ? 'badge text-bg-danger'
                        : 'badge text-bg-success'
                    }
                  >
                    {detalle.esComReqAgrDet === 0 ? 'Requerido' : 'Completo'}
                  </span>
                </TableCell>
                <TableCell>
                  <div className="btn-toolbar">
                    <CustomDialogUpdateOperation
                      detalle={detalle}
                      disabled={detalle.esComReqAgrDet === 1}
                      onUpdateOperation={onUpdateDetalle}
                      formato={{
                        nombre: 'nomProd',
                        cantidad: 'canReqAgrDet',
                        medida: 'simMed'
                      }}
                    />
                    <CustomDialogDeleteOperation
                      detalle={detalle}
                      disabled={detalle.esComReqAgrDet === 1}
                      onDeleteOperation={onDeleteDetalle}
                      formato={{
                        nombre: 'nomProd',
                        cantidad: 'canReqAgrDet',
                        medida: 'simMed'
                      }}
                    />
                    <CustomDialogConfirmOperation
                      detalle={detalle}
                      disabled={detalle.esComReqAgrDet === 1}
                      onConfirmOperation={onCheckDetalle}
                      formato={{
                        nombre: 'nomProd',
                        cantidad: 'canReqAgrDet',
                        medida: 'simMed'
                      }}
                    />
                    <DialogConsultarStock
                      detalle={detalle}
                    />
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}
// Dialogo de consulta de stock
const DialogConsultarStock = ({ detalle }) => {
  const [open, setOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [stockAlmacen, setStockAlmacen] = React.useState({
    almacenPrincipal: null,
    almacenAuxiliar: null
  })
  const { almacenPrincipal, almacenAuxiliar } = stockAlmacen

  const handleClickOpen = async () => {
    setLoading(true)
    // hacemos una consulta de stock
    const resultPeticion = await getStockAlmacenes(detalle.idProdt)
    const { message_error, description_error, result } = resultPeticion
    if (message_error.length === 0) {
      setStockAlmacen({
        almacenAuxiliar: result.auxiliar[0],
        almacenPrincipal: result.principal[0]
      })
      setOpen(true)
    } else {
      alertError(description_error)
    }
    setLoading(false)
  }
  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div>
      <button
        className="btn btn-secondary me-2"
        title="Stock almacenes"
        onClick={handleClickOpen}
      >
        <img
          alt="boton stock almacenes"
          src={iconStockAlmacenes}
          height={25}
          width={25}
        />
      </button>
      <BootstrapDialog
        maxWidth={'lg'}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Stock almacenes
        </DialogTitle>
        {loading
          ? <DialogContent dividers>Cargando ...</DialogContent>
          : <DialogContent dividers>
            <p className='d-flex flex-row'>
              <strong className='me-2'>Almacen principal:</strong>
              <span>{almacenPrincipal !== null ? `${almacenPrincipal} ${detalle.simMed}` : 'Sin datos'}</span>
            </p>
            <p className='d-flex flex-row'>
              <strong className='me-2'>Almacen auxiliar:</strong>
              <span>{almacenAuxiliar !== null ? `${almacenAuxiliar} ${detalle.simMed}` : 'Sin datos'}</span>
            </p>
          </DialogContent>}
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cerrar
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  )
}
