import { HomeVentas } from '../pages/HomeVentas'
import { RouterAlmacenStockVentas } from '../pages/almacen/RouterAlmacenStockVentas'
import { RouterOperacionDevolucion } from '../pages/operacion-devolucion/RouterOperacionDevolucion'
import LayoutModulo from './../../layout/LayoutModulo'

export const RouterVentas = [
  {
    path: '',
    element: <HomeVentas />
  },
  {
    path: 'stock-almacen',
    element: <LayoutModulo />,
    children: RouterAlmacenStockVentas
  },
  {
    path: 'operacion-devolucion',
    element: <LayoutModulo />,
    children: RouterOperacionDevolucion
  }
]
