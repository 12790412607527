import React, { useEffect, useState } from 'react'
import { FormatDateMYSQL } from '../../../utils/functions/FormatDate'
// import { listOperacionesDevolucion } from '../../../helpers/operacion-devolucion/listOperacionesDevolucion'
import {listSolicitudDevolucion} from '../../helpers/solicitud-devolucion/listSolicitudDevolucion'
import FechaPickerMonth from '../../../components/Fechas/FechaPickerMonth'
import {RowOperacionSolicitudDevolucion} from '../../components/operacion-solicitud-devolucion/RowOperacionSolicitudDevolucion'
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material'
// import { RowOperacionDevolucionNoRetorno } from '../../components/operacion-devolucion/RowOperacionDevolucionNoRetorno'


export const ListOperacionDevolucionVentas = () => {
  // ESTADOS PARA LOS FILTROS PERSONALIZADOS
  const [dataOperacionDevolucion, setdataOperacionDevolucion] = useState([])

  // ESTADOS PARA FILTROS GENERALES DE FECHA
  // filtros
  const [formState, setformState] = useState({
    fechaInicio: FormatDateMYSQL(),
    fechaFin: FormatDateMYSQL()
  })
  // Filtros generales que hacen nuevas consultas
  const handleFechaInicioChange = (newfecEntSto) => {
    const dateFormat = newfecEntSto.split(' ')[0]
    setformState({
      ...formState,
      fechaInicio: dateFormat
    })

    // armamos el body
    const body = {
      ...formState,
      fechaInicio: dateFormat
    }
    obtenerDataOperacionDevolucion(body)
  }

  const handleFechaFinChange = (newfecEntSto) => {
    const dateFormat = newfecEntSto.split(' ')[0]
    setformState({
      ...formState,
      fechaFin: dateFormat
    })

    // armamos el body
    const body = {
      ...formState,
      fechaFin: dateFormat
    }
    obtenerDataOperacionDevolucion(body)
  }

  const obtenerDataOperacionDevolucion = async (body = null) => {
    const resultPeticion = await listSolicitudDevolucion(body)
    const { result, message_error, description_error } = resultPeticion
    // console.log(resultPeticion)
    if (message_error.length === 0) {
      setdataOperacionDevolucion(result)
    } else {
      alert(description_error)
    }
    
  }

  useEffect(() => {
    obtenerDataOperacionDevolucion()
  }, [])

  return (
    <>
      <div className="container-fluid">
        <div className="row d-flex mt-4 mb-4">
          <div className="col-12">
            <div className="row" style={{ border: '0px solid black' }}>
              <div
                className="col-2"
                style={{
                  border: '0px solid black',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <FechaPickerMonth
                  onNewfecEntSto={handleFechaInicioChange}
                  label="Desde"
                />
              </div>
              <div
                className="col-2"
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <FechaPickerMonth
                  onNewfecEntSto={handleFechaFinChange}
                  label="Hasta"
                />
              </div>
              <div
                className="col-6 d-flex justify-content-end align-items-center"
                onClick={() => {
                  // console.log(row)
                  window.open(
                    `/ventas/operacion-devolucion/crear`,
                    '_blank'
                  )
                }}
              >
                <div className="row">
                  <div className="col-12 btn btn-primary d-inline-flex justify-content-end align-items-center" style={{ marginRight: 'pointer' }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-plus-circle-fill me-2"
                      viewBox="0 0 16 16"
                    >
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
                    </svg>
                    Agregar
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* TABLA DE CONTENIDO */}
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow
                sx={{
                  '& th': {
                    color: 'rgba(96, 96, 96)',
                    backgroundColor: '#f5f5f5'
                  }
                }}
              >
                <TableCell align="left" width={20}>
                  <b>Operacion</b>
                </TableCell>
                <TableCell align="left" width={40}>
                  <b>Lote</b>
                </TableCell>
                <TableCell align="center" width={50}>
                  <b>Estado</b>
                </TableCell>
                <TableCell align="center" width={220}>
                  <b>Producto</b>
                </TableCell>
                <TableCell align="left" width={60}>
                  <b>Fecha creación</b>
                </TableCell>
                <TableCell align="left" width={50}>
                  <b>Acciones</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dataOperacionDevolucion.map((element) => (
                <RowOperacionSolicitudDevolucion
                  key={element.id}
                  detalle={element}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  )
}
