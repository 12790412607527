import { useRequisicionGeneralMateriales } from '../../hooks/requisicion-general-materiales/useRequisicionGeneralMateriales'
import { Link } from 'react-router-dom'
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { RowRequisicionGeneralMateriales } from '../../components/requisicion-materiales/RowRequisicionGeneralMateriales'
import { CustomFilterDateRange } from '../../../components/CustomComponents/CustomFilterDateRange'
import { CustomLoading } from '../../../components/CustomComponents/CustomLoading'
import config from '../../../config'
import axios from 'axios'
import { useAuth } from '../../../hooks/useAuth'
import { useDatePickerRange } from '../../../hooks/useDatePickerRange'

export const ListRequisicionGeneralMateriales = () => {
  const {
    requisicionMateriales,
    generatePDFRequisicionMateriales,
    loading,
    dateState,
    handleStartDateChange: requisicionHandleStartDateChange,
    handleEndDateChange: requisicionHandleEndDateChange,
  } = useRequisicionGeneralMateriales()

  // Desestructura los resultados del segundo hook
  const {
    dateState: pickerDateState,
    handleStartDateChange: pickerHandleStartDateChange,
    handleEndDateChange: pickerHandleEndDateChange,
  } = useDatePickerRange()

  const { user } = useAuth()
  
  // Combina los estados de fecha
  const combinedDateState = {
    ...dateState
  }

  const exportarReporteGeneral = async () => {
    try {
      // Realiza la solicitud POST y espera la respuesta
      const data = {
        idAre: user.idAre,
        ...dateState // Usa el estado combinado para el rango de fechas
      }

      const domain = config.API_URL;
      const path = '/general/requisicion-materiales/reporteConsolidadoMateriales.php';

      // Realiza la solicitud para descargar el archivo
      const fileResponse = await axios({
        url: domain + path,
        data,
        method: 'POST',
        responseType: 'blob',
      });

      // Crea un enlace y descarga el archivo
      const url = window.URL.createObjectURL(new Blob([fileResponse.data]));
      const a = document.createElement('a');
      a.href = url;
      a.download = 'reporte-requisiciones-generales.xlsx';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      alert('Error al descargar el archivo');
      console.error('Error:', error);
    }
  }

  return (
    <>
      <div className="container-fluid">
        <div className="row d-flex mt-4">
          <CustomFilterDateRange
            dateState={combinedDateState}
            handleStartDateChange={(value) => {
              requisicionHandleStartDateChange(value)
              pickerHandleStartDateChange(value)
            }}
            handleEndDateChange={(value) => {
              requisicionHandleEndDateChange(value)
              pickerHandleEndDateChange(value)
            }}
          />
          <div className="col-6 d-flex justify-content-end align-items-center">
            <div className="row me-4">
              <Link
                to={'crear'}
                className="btn btn-primary"
              >
                Crear requisición
              </Link>
            </div>
            <div className="row me-4">
              <button
                onClick={() => {
                  exportarReporteGeneral();
                }}
                
                className="btn btn-success"
              >
                Exportar todo
              </button>
            </div>
          </div>
        </div>
        {/* TABLA DE CONTENIDO */}
        <TableContainer component={Paper} className='mt-4'>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow
                sx={{
                  '& th': {
                    color: 'rgba(96, 96, 96)',
                    backgroundColor: '#f5f5f5'
                  }
                }}
              >
                <TableCell align="left" width={50}>
                  <b>Código requisición</b>
                </TableCell>
                <TableCell align="center" width={100}>
                  <b>Estado</b>
                </TableCell>
                <TableCell align="center" width={200}>
                  <b>Motivo requisición</b>
                </TableCell>
                <TableCell align="center" width={70}>
                  <b>Área</b>
                </TableCell>
                <TableCell align="center" width={50}>
                  <b>Fecha requerimiento</b>
                </TableCell>
                <TableCell align="left" width={150}>
                  <b>Acciones</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                requisicionMateriales.map((item) => (
                  <RowRequisicionGeneralMateriales key={item.id}
                    item={item}
                    onGeneratePDF={generatePDFRequisicionMateriales}
                  />
                ))
              }
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      {/* LOADING */}
      <CustomLoading
        open={loading}
      />
    </>
  )
}
