import React from 'react'
import Button from '@mui/material/Button'
import * as XLSX from 'xlsx'

const ExportExcel = ({ exelData }) => {
  //Ingresamos la data y los titulos de las columnas
  exelData = exelData.map((row) => ({
    Codigo: row.codProd2,
    Siigo: row.codProd,
    Producto: row.nomProd,
    Clase: row.desCla,
    Medida: row.simMed,
    Almacen: row.nomAlm,
    Total: row.canSto,
    Cantidad_Disponible: row.canStoDis
  }
  ))
  const exportExcel = async () => {
    // inicilizamos la variable workbook para genera nuestro documento de excel
    const workbook = XLSX.utils.book_new()
    // Crear la hoja de trabajo con datos de excel
    const worksheet = XLSX.utils.json_to_sheet(exelData, { origin: 0 })
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1')

    // Ajustar el ancho de las columnas
    worksheet['!cols'] = [
      { wch: 15 }, // Codigo
      { wch: 10 }, // Siigo
      { wch: 50 }, // Producto
      { wch: 20 }, // Clase
      { wch: 10 }, // Medida
      { wch: 30 }, // Almacen
      { wch: 20 }, // Total
      { wch: 20 }  // Cantidad_Disponible
    ]

     // Aplicar estilos al encabezado existente
    //  const range = XLSX.utils.decode_range(worksheet['!ref'])
    //  for (let C = range.s.c; C <= range.e.c; C++) {
    //    const cellAddress = XLSX.utils.encode_cell({ c: C, r: 1 }) // La fila 1 es la fila del encabezado
    //    if (!worksheet[cellAddress]) continue
    //    worksheet[cellAddress].s = {
    //      font: { bold: true },
    //      fill: {
    //        fgColor: { rgb: 'FFFF00' } // Color de fondo amarillo
    //      },
    //      alignment: { horizontal: 'center' }
    //    }
    //  }
    const headerStyle = {
      font: { bold: true },
      fill: { fgColor: { rgb: 'FFFF00' } }, // Color de fondo amarillo
      alignment: { horizontal: 'center' }
    }
    worksheet['A1'].s = headerStyle // Código
    worksheet['B1'].s = headerStyle // Siigo
    worksheet['C1'].s = headerStyle // Producto
    worksheet['D1'].s = headerStyle // Clase
    worksheet['E1'].s = headerStyle // Medida
    worksheet['F1'].s = headerStyle // Almacen
    worksheet['G1'].s = headerStyle // Total
    worksheet['H1'].s = headerStyle // Cantidad_Disponible
    //XLSX.writeFile(workbook, 'Stock.xlsx')
    // Obtener fecha y hora actuales para el nombre del archivo
    const now = new Date()
    const fileName = `Stock_${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2, '0')}-${String(now.getDate()).padStart(2, '0')}_${String(now.getHours()).padStart(2, '0')}-${String(now.getMinutes()).padStart(2, '0')}.xlsx`

    // Añadir la hoja de trabajo al libro
    // XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1')

    // Escribir el archivo
    XLSX.writeFile(workbook, fileName)
  }

  return (
    <>
      <Button
        variant="contained"
        size="small"
        sx={{ width: 150, margin: 0.5, cursor: 'pointer' }}
        onClick={(e) => exportExcel()}
      >
        Export excel
      </Button>
    </>
  )
}
export default ExportExcel
