import React from 'react'
import { BootstrapDialog } from '../BootstrapDialog'
import { Button, DialogActions, DialogContent, DialogTitle } from '@mui/material'

export const CustomDialogDeleteOperation = (
  {
    detalle,
    onDeleteOperation,
    disabled,
    formato = {
      nombre: 'nomProd',
      cantidad: 'canReq',
      medida: 'simMed'
    }
  }
) => {
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div>
      <button
        className='btn btn-danger me-2'
        onClick={handleClickOpen}
        disabled={disabled}
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
          <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z"/>
          <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z"/>
        </svg>
      </button>
      <BootstrapDialog
        maxWidth={'lg'}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle sx={{ m: 0, p: 2, fontWeight: 'bold' }} id="customized-dialog-title">
            Operación eliminación
        </DialogTitle>
        <DialogContent dividers>
          <span className="me-2 d-block fw-semibold">Producto: </span>
          {detalle[formato.nombre]}
          <span className="me-2 d-block mt-2 fw-semibold">Total requisicion:</span>
          {detalle[formato.cantidad]}
          <span className="ms-2">{detalle[formato.medida]}</span>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
              Cerrar
          </Button>
          <Button
            color="error"
            autoFocus
            onClick={() => {
              // procesamos la eliminacion del detalle de la requisicion
              onDeleteOperation(detalle)
              // cerramos el cuadro de dialogo
              handleClose()
            }}
          >
              Aceptar
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  )
}
