import React, { useEffect, useState } from 'react'
import { FormatDateMYSQL } from '../../../utils/functions/FormatDate'
// import { listOperacionesDevolucion } from '../../../helpers/operacion-devolucion/listOperacionesDevolucion'
import { listProduccionLote } from '../../helpers/produccion-lote/listProduccionLote'
import { getProduccionLote } from '../../../produccion/helpers/produccion_lote/getProduccionLote'
import FechaPickerMonth from '../../../components/Fechas/FechaPickerMonth'
import { RowGetProduccionLote } from '../../components/produccion-lote/RowGetProduccionLote'
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from '@mui/material'
// import { RowOperacionDevolucionNoRetorno } from '../../components/operacion-devolucion/RowOperacionDevolucionNoRetorno'


export const ListLoteProduccion = () => {
    // ESTADOS PARA LOS FILTROS PERSONALIZADOS
    const [dataProduccionLote, setdataProduccionLote] = useState([])

    // ESTADOS PARA FILTROS GENERALES DE FECHA
    // filtros
    const [formState, setformState] = useState({
        fecProdLotIni: FormatDateMYSQL(),
        fecProdLotFin: FormatDateMYSQL()
    })
    // Filtros generales que hacen nuevas consultas
    const handleFechaInicioChange = (newfecEntSto) => {
        const dateFormat = newfecEntSto.split(' ')[0]
        setformState({
            ...formState,
            fecProdLotIni: dateFormat
        })

        // armamos el body
        const body = {
            ...formState,
            fecProdLotIni: dateFormat
        }
        obtenerdataProduccionLote(body)
    }

    const handleFechaFinChange = (newfecEntSto) => {
        const dateFormat = newfecEntSto.split(' ')[0]
        setformState({
            ...formState,
            fecProdLotFin: dateFormat
        })

        // armamos el body
        const body = {
            ...formState,
            fecProdLotFin: dateFormat
        }
        obtenerdataProduccionLote(body)
    }

    const obtenerdataProduccionLote = async (body = null) => {
        const resultPeticion = await listProduccionLote(body)
        const { result, message_error, description_error } = resultPeticion
        console.log(result)
        if (message_error.length === 0) {
            setdataProduccionLote(result)
        } else {
            alert(description_error)
        }

    }

    useEffect(() => {
        obtenerdataProduccionLote()
    }, [])

    return (
        <>
            <div className="container-fluid">
                <div className="row d-flex mt-4 mb-4">
                    <div className="col-12">
                        <div>
                        <h2 class="mt-4 text-center">Produccion por lotes - Desmedros</h2>
                        </div>
                        <div className="row" style={{ border: '0px solid black' }}>
                            <div
                                className="col-2"
                                style={{
                                    border: '0px solid black',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}
                            >
                                <FechaPickerMonth
                                    onNewfecEntSto={handleFechaInicioChange}
                                    label="Desde"
                                />
                            </div>
                            <div
                                className="col-2"
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}
                            >
                                <FechaPickerMonth
                                    onNewfecEntSto={handleFechaFinChange}
                                    label="Hasta"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {/* TABLA DE CONTENIDO */}
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                        <TableHead>
                            <TableRow
                                sx={{
                                    '& th': {
                                        color: 'rgba(96, 96, 96)',
                                        backgroundColor: '#f5f5f5'
                                    }
                                }}
                            >
                                <TableCell align="left" width={20}>
                                    <b>Lote</b>
                                </TableCell>
                                <TableCell align="left" width={40}>
                                    <b>Operación</b>
                                </TableCell>
                                <TableCell align="center" width={220}>
                                    <b>Producto</b>
                                </TableCell>
                                <TableCell align="center" width={50}>
                                    <b>Inicio</b>
                                </TableCell>
                                <TableCell align="left" width={60}>
                                    <b>Estado</b>
                                </TableCell>
                                <TableCell align="left" width={50}>
                                    <b>Acciones</b>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {dataProduccionLote.map((element) => (
                                <RowGetProduccionLote
                                    key={element.id}
                                    detalle={element}
                                />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </>
    )
}
