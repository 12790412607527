import { ListLoteProduccion } from './ListProduccionProcesos'
import { CrearProduccionProcesos } from './CrearProduccionProcesos'
import { ViewLoteProduccion } from './ViewProduccionProcesoso'
// import { ListProduccionLote2 } from './ListProduccionLote2'

export const RouterProduccionProcesos = [
  {
    path: '',
    element: <ListLoteProduccion />
  },
  // {
  //   path: '',
  //   element: <ListProduccionLote2 />
  // },
  {
    path: 'crear',
    element: <CrearProduccionProcesos />
  },
  {
    path: 'view',
    element: <ViewLoteProduccion />
  }
]